import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionGroup, Button, Surface, Txt } from '@vst/beam';

import {
  SESSION_STORAGE_VARIABLES,
  Storage,
  VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID,
  useScreenResolution,
} from '@mfe/shared/util';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Locale } from '@mfe/shared/schema-types';
import {
  getPendingInstallation,
  selectingPendingInstallation,
} from '@mfe/to-be-migrated/redux/pendingInstallation';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectConfig } from '@mfe/shared/redux/config';
import {
  AEMContentProvider,
  AemFeature,
  PlanChangePendingBadge,
  SKIP_PARAMETER_CONSTANT,
  createAemUrl,
  useCanChangePlan,
  useHasPlanChangePending,
  useShowAddonsShop,
} from '@mfe/shared/components';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import {
  getBroadbandLabelAttributes,
  selectBroadbandLabelAttributes,
} from '@mfe/to-be-migrated/redux/broadbandLabel/broadbandLabelSlice';
import {
  getPortfolio,
  selectPortfolio,
} from '@mfe/to-be-migrated/redux/portfolio';
import {
  getCharacteristics,
  getDownloadSpeeds,
  getPrice,
  selectCurrentPlanPrice,
  selectDownloadSpeeds,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';
import {
  refetchCurrentAddOns,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';
import { useShouldRefetchCurrentAddons } from '@mfe/features/add-ons';
import { ManagePlanModal } from '@mfe/features/click-to-disconnect';

import { ScheduledAlert } from './ScheduledAlert';
import { UnscheduledAlert } from './UnscheduledAlert';
import { PlanName } from './PlanName';
import { Price } from './Price';
import { DownloadSpeed } from './DownloadSpeed';
import { Characteristics } from './Characteristics';
import { EquipmentShippingAlert } from './EquipmentShippingAlert';
import { AddonsCard } from './AddonsCard';

import styles from '../BodyContent.module.scss';
import loadingStyles from './Loading.module.scss';

interface Props {
  navigateToChangePlan: () => void;
  navigateToScheduleInstallation: () => void;
}

export const InternetPlan = ({
  navigateToChangePlan,
  navigateToScheduleInstallation,
}: Props) => {
  const dispatch = useDispatch();

  const shouldRefetchCurrentAddOns = useShouldRefetchCurrentAddons();
  const { addOnOrderId } = useSelector(selectAddOns);
  const showAddonsShop = useShowAddonsShop();

  const { portfolio } = useSelector(selectPortfolio);
  const {
    characteristics: { id: productTypeId },
  } = useSelector(selectPlanCharacteristics);
  const { price } = useSelector(selectCurrentPlanPrice);
  const {
    data: { downloadSpeed },
  } = useSelector(selectDownloadSpeeds);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (shouldRefetchCurrentAddOns) {
      dispatch(refetchCurrentAddOns(addOnOrderId as string));
    }
  }, []);

  React.useEffect(() => {
    dispatch(getPendingInstallation());
    dispatch(getBroadbandLabelAttributes());
  }, []);

  React.useEffect(() => {
    // Fetch portfolio in advance, to later pass to configureCart
    if (!portfolio) dispatch(getPortfolio());

    if (!productTypeId) dispatch(getCharacteristics());
    if (!price) dispatch(getPrice());
    if (!downloadSpeed) dispatch(getDownloadSpeeds());
  }, [dispatch, portfolio, productTypeId, price, downloadSpeed]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
      <PlanCard
        navigateToChangePlan={navigateToChangePlan}
        navigateToScheduleInstallation={navigateToScheduleInstallation}
        openManagePlanModal={() => setIsModalOpen(true)}
      />
      {showAddonsShop && <AddonsCard />}
      <ManagePlanModal
        isOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        navigateToChangePlan={navigateToChangePlan}
      />
    </div>
  );
};

export const PlanCard = ({
  navigateToChangePlan,
  navigateToScheduleInstallation,
  openManagePlanModal,
}: Props & { openManagePlanModal: () => void }) => {
  const { t } = useTranslation(['NewProfile', 'Global']);
  const { isSmall, isExtraSmall } = useScreenResolution();

  const {
    showChangePlanSMB,
    aemBaseUrl,
    showBroadbandLabels,
    showClickToDisconnect,
  } = useSelector(selectConfig);

  const { hasActivationError } = useSelector(selectChangePlan);

  const {
    data: { upi, serviceAreaId, serviceId, satelliteName, iduDeviceModel },
    loading: broadbandLabelAttributesLoading,
  } = useSelector(selectBroadbandLabelAttributes);

  const {
    characteristics: {
      name: planName,
      uploadSpeed,
      isGoodFor,
      bestFor,
      titleAddOns,
      dataCap,
    },
    loading: characteristicsLoading,
  } = useSelector(selectPlanCharacteristics);

  const { price, loading: priceLoading } = useSelector(selectCurrentPlanPrice);

  const {
    data: { downloadSpeed, downloadUnits },
    loading: downloadSpeedLoading,
  } = useSelector(selectDownloadSpeeds);

  const {
    loading: alertLoading,
    hasPendingInstallation,
    schedule,
    isEquipmentShipped,
  } = useSelector(selectingPendingInstallation);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    user: { productKind },
  } = useSelector(selectUser);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const canFlowBeAccessed = userLocale === Locale.EnUs;
  const canChangePlan = useCanChangePlan();
  const showChangePlanButton =
    canFlowBeAccessed ||
    accountType === AccountType.Residential ||
    showChangePlanSMB;

  const showBroadbandLabel =
    showBroadbandLabels && !broadbandLabelAttributesLoading;

  const hasDataLoaded =
    !characteristicsLoading && !priceLoading && !downloadSpeedLoading;

  const handleNavScheduleInstall = () => {
    Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
    navigateToScheduleInstallation();
  };

  const showMissingInfoNotice = useShowMissingInfoNotice();
  const hasPlanChangePending = useHasPlanChangePending();

  const isChangePlanDisabled =
    productKind === ProductInstanceTypes.WirelessInternet ||
    !canChangePlan ||
    (hasPendingInstallation && alertLoading) ||
    hasActivationError;

  const managePlanButtonProps = {
    'data-cy': 'manage-plan-button',
    onClick: openManagePlanModal,
    disabled: false,
    label: 'Manage plan',
  };

  const changePlanButtonProps = {
    'data-cy': 'change-plan-button',
    onClick: navigateToChangePlan,
    disabled: isChangePlanDisabled,
    label: t('Usage:buttons.changePlan'),
  };

  const cardButtonProps = showClickToDisconnect
    ? managePlanButtonProps
    : changePlanButtonProps;

  return (
    <Surface
      style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      data-cy="internet-plan-card-surface"
      variant="primary"
      radius={isSmall || isExtraSmall ? '0px' : '16px'}
    >
      <div className={styles['cardTitle']}>
        <Txt variant="heading5" component="span" color="regular">
          {t('NewProfile:myPlanCardTitle')}
        </Txt>

        {hasPlanChangePending ? (
          <PlanChangePendingBadge />
        ) : (
          showChangePlanButton && (
            <Button buttonSize="small" variant="secondary" {...cardButtonProps}>
              {cardButtonProps.label}
            </Button>
          )
        )}
      </div>

      <Surface
        pt="24px"
        px={{ xs: '16px', sm: '24px' }}
        className={styles['planInfoContainer']}
      >
        {alertLoading ? (
          <div className={loadingStyles['alertLoading']} />
        ) : (
          hasPendingInstallation &&
          (schedule ? (
            <ScheduledAlert handleRedirect={handleNavScheduleInstall} />
          ) : (
            <UnscheduledAlert handleRedirect={handleNavScheduleInstall} />
          )) &&
          isEquipmentShipped && <EquipmentShippingAlert />
        )}

        {hasDataLoaded && showMissingInfoNotice && (
          <Txt variant="bodySmallItalic" color="subtle">
            {t('InternetPlan.missingPlanInfo')}
          </Txt>
        )}
        <div className={styles['planHeader']} data-cy="planDetails">
          <PlanName name={planName} loading={characteristicsLoading} />
          <Price price={price} loading={priceLoading} />
        </div>
        <div className={styles['attributesContainer']}>
          <DownloadSpeed
            downloadSpeed={downloadSpeed}
            downloadUnits={downloadUnits}
            loading={downloadSpeedLoading}
          />
          <Characteristics
            isGoodFor={isGoodFor}
            bestFor={bestFor}
            titleAddOns={titleAddOns}
            dataCap={dataCap}
            uploadSpeed={uploadSpeed}
            loading={characteristicsLoading}
          />
        </div>
        <AccordionGroup>
          <Accordion
            className={styles['broadbandFactsAccordion']}
            title={
              <Txt variant="labelMedium">
                {t('InternetPlan.broadbandFacts')}
              </Txt>
            }
          >
            {showBroadbandLabel && (
              <AEMContentProvider
                src={createAemUrl({
                  baseUrl: aemBaseUrl,
                  locale: userLocale,
                  feature: AemFeature.PLAN_LABEL,
                  accountType,
                  additionalSelectors: ['pidi'],
                  suffixes: {
                    upi,
                    serviceAreaId,
                    offerId: SKIP_PARAMETER_CONSTANT,
                    satelliteName,
                    serviceId,
                    iduDeviceModel,
                  },
                })}
                id={AemFeature.PLAN_LABEL}
                style={{ textAlign: 'left' }}
              />
            )}
          </Accordion>
        </AccordionGroup>
      </Surface>
    </Surface>
  );
};

const useShowMissingInfoNotice = () => {
  const {
    characteristics: {
      id: productTypeId,
      name: planName,
      uploadSpeed,
      isUnleashed,
    },
  } = useSelector(selectPlanCharacteristics);

  const { price, errors: priceError } = useSelector(selectCurrentPlanPrice);

  const {
    data: { downloadSpeed },
  } = useSelector(selectDownloadSpeeds);

  const hasVoicePlan = productTypeId === VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID;

  if (hasVoicePlan) {
    return Boolean(priceError);
  }

  const attributes = isUnleashed
    ? [planName, price]
    : [planName, price, downloadSpeed, uploadSpeed];

  return attributes.some((attr) => !attr);
};
